<template>
  <div>
    <b-card>
      <h3 class="text-center mb-0">{{ work_number }} | {{ name }}</h3>
    </b-card>
    <form-wizard
      color="#3e74c7"
      :title="null"
      :subtitle="null"
      ref="wizard"
      :enable-finish-button="true"
      layout="vertical"
      finish-button-text="Frissítés"
      next-button-text="Tovább"
      next-button-icon="fa fa-arrow-right"
      back-button-text="Vissza"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
      tabindex="3"
    >

      <!-- account datails tab -->
      <tab-content title="Projekt adatok">
        <b-row>
          <b-col
            cols="8"
            class="mb-2"
          >
            <h5 class="mb-0">
              Projekt adatai
            </h5>
            <small class="text-muted">
              Kérlek add meg az információkat
            </small>
          </b-col>
          <b-col
            cols="4"
            class="mb-2 justify-content-end d-flex"
          >
            <button class="btn btn-primary" @click="formSubmitted">Frissítés</button>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Munka azonosító"
              label-for="work_number"
            >
              <b-form-select
                id="work_number"
                v-model="work_number"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="work_numberOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Rövid megnevezés"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Rövid megnevezés"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Dátum"
              label-for="start_date"
            >
              <b-form-input
                id="start_date"
                v-model="start_date"
                type="date" max="9999-12-31"
                placeholder="Dátum"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Megrendelő"
              label-for="customer"
            >
              <b-form-input
                id="customer"
                v-model="customer"
                placeholder="Megrendelő"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Építési engedély szám"
              label-for="building_permit"
            >
              <b-form-input
                id="building_permit"
                v-model="building_permit"
                placeholder="Építési engedély szám"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Melyik céghez tartozik"
              label-for="monolit"
            >
              <b-form-select
                id="monolit"
                v-model="monolit"
                :options="monolitOptions"
                placeholder="Melyik céghez tartozik"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Hosszú megnevezés"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                placeholder="Hosszú megnevezés"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Vállalt összeg"
              label-for="price"
            >
              <b-form-input
                id="price"
                type="text"
                v-model="price"
                @input="numberWithCommas()"
                placeholder="Vállalt összeg"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="FAD"
              label-for="fad"
            >
              <b-form-select
                id="fad"
                v-model="fad"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="fadOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Építés vezető"
              label-for="foreman"
            >
              <b-form-select
                id="foreman"
                v-model="foreman"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="employeeOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Előkészítő"
              label-for="preparatory"
            >
              <b-form-select
                id="preparatory"
                v-model="preparatory"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="employeeOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Vállalt befejezés napja"
              label-for="end_date"
            >
              <b-form-input
                id="end_date"
                v-model="end_date"
                type="date" max="9999-12-31"
                placeholder="Vállalt befejezés napja"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Biztosító"
              label-for="insurance"
            >
              <b-form-input
                id="insurance"
                v-model="insurance"
                placeholder="Biztosító"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Biztosítás lejárta"
              label-for="insurance_end_date"
            >
              <b-form-input
                id="insurance_end_date"
                v-model="insurance_end_date"
                placeholder="Biztosítás lejárta"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Mérföldkövek">
        <b-row class="mb-2">
          <b class="col-lg-8 mt-2 mb-1">Mérföldkövek</b>
          <b-col
            cols="4"
            class="mb-2 justify-content-end d-flex"
          >
            <button class="btn btn-primary" @click="formSubmitted">Frissítés</button>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Mérföldkő neve"
              label-for="milestone_name"
            >
              <b-form-input
                id="milestone_name"
                v-model="milestone_name"
                type="text"
                placeholder="Mérföldkő neve"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group
              label="Teljesítés dátuma"
              label-for="milestone_date"
            >
              <b-form-input
                id="milestone_date"
                v-model="milestone_date"
                type="date" max="9999-12-31"
                placeholder="Teljesítés dátuma"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <br class="d-none d-md-block d-lg-block"/>
            <button class="btn btn-primary w-100" @click="addToMilestoneList">
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
              />
            </button>
          </b-col>
          <b-col md="12">(Elszámolás: {{payment}})</b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-row v-for="(milestone, index) in milestones" :key="index">
              <b-col md="12" v-if="index == 0">
                <hr/>
              </b-col>
              <b-col v-if="milestone.done" md="1">
                <button class="btn w-100 text-success" @click="changeMilestoneStatus(!milestone.done, index)">
                  <feather-icon
                    icon="CheckCircleIcon"
                    size="20"
                  />
                </button>
              </b-col>
              <b-col v-else md="1">
                <button class="btn w-100 text-danger" @click="changeMilestoneStatus(!milestone.done, index)">
                  <feather-icon
                    icon="MinusCircleIcon"
                    size="20"
                  />
                </button>
              </b-col>
              <b-col md="5">
                <b>{{ milestone.name }}</b>
              </b-col>
              <b-col md="4">
                {{ milestone.date }}
              </b-col>
              <b-col md="1">
                <button class="btn btn-primary w-100" @click="editFromMilestoneList(index)">
                  <feather-icon
                    icon="Edit2Icon"
                    size="16"
                  />
                </button>
              </b-col>
              <b-col md="1">
                <button class="btn btn-primary w-100" @click="removeFromMilestoneList(index)">
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                  />
                </button>
              </b-col>
              <b-col md="12">
                <hr/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Alvállalkozók">
        <b-row>
          <b-col md="12">
            <b-row class="mb-2">
              <b class="col-lg-8 mt-2 mb-1">Alvállalkozók</b>
              <b-col
                cols="4"
                class="mb-2 justify-content-end d-flex"
              >
                <button class="btn btn-primary" @click="formSubmitted">Frissítés</button>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Alvállakozó"
                  label-for="subcontractor"
                >
                  <b-form-select
                    id="subcontractor"
                    v-model="selectedSubcontractor"
                    :options="subcontractorOptions"
                    :value-field="'id'"
                    :text-field="'company_name'"
                    placeholder="Mérföldkő neve"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Elvégzendő munka"
                  label-for="selectedJob"
                >
                  <b-form-select
                    id="selectedJob"
                    v-model="selectedJob"
                    :options="jobOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Kezdés dátuma"
                  label-for="subcontractor_start"
                >
                  <b-form-input
                    id="subcontractor_start"
                    v-model="subcontractor_start"
                    type="date" max="9999-12-31"
                    placeholder="Kezdés dátuma"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Befejezés dátuma"
                  label-for="subcontractor_end"
                >
                  <b-form-input
                    id="subcontractor_end"
                    v-model="subcontractor_end"
                    type="date" max="9999-12-31"
                    placeholder="Befejezés dátuma"
                  />
                </b-form-group>
              </b-col>
              <b-col md="1">
                <br class="d-none d-md-block d-lg-block"/>
                <button class="btn btn-primary w-100" @click="addToSubcontractorList">
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="16"
                  />
                </button>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Megjegyzés"
                  label-for="subcontractor_text"
                >
                  <b-form-textarea
                    id="subcontractor_text"
                    v-model="subcontractor_text"
                    type="date" max="9999-12-31"
                    placeholder="Megjegyzés"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-row v-for="(subcontractor, index) in subcontractors" :key="index">
                  <b-col md="12" v-if="index == 0">
                    <hr/>
                  </b-col>
                  <b-col md="3">
                    <b>{{ subcontractor.name }}</b>
                  </b-col>
                  <b-col md="2">
                    <b>{{ subcontractor.job }}</b>
                  </b-col>
                  <b-col md="3">
                    {{ subcontractor.date }}
                  </b-col>
                  <b-col md="2">
                    {{ subcontractor.date_end }}
                  </b-col>
                  <b-col md="1">
                    <button class="btn btn-primary w-100" @click="editFromSubcontrator(index)">
                      <feather-icon
                        icon="EditIcon"
                        size="16"
                      />
                    </button>
                  </b-col>
                  <b-col md="1">
                    <button class="btn btn-primary w-100" @click="removeFromSubcontractorsList(index)">
                      <feather-icon
                        icon="MinusCircleIcon"
                        size="16"
                      />
                    </button>
                  </b-col>
                  <b-col md="12">
                    {{ subcontractor.text }}
                  </b-col>
                  <b-col md="12">
                    <hr/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Pót munka">
        <b-row>
          <b-col md="12">
            <b-row class="mb-2">
              <b class="col-lg-12 mt-2 mb-1">Pót munka rögzítés</b>
              <b-col md="11">
                <b-form-group
                  label="Leírás"
                  label-for="extra_description"
                >
                  <b-form-textarea
                    id="extra_description"
                    v-model="extra_description"
                    placeholder="Pót munka leírása"
                  />
                </b-form-group>
              </b-col>
              <b-col md="1">
                <br class="d-none d-md-block d-lg-block"/>
                <button class="btn btn-primary w-100" @click="addToExtraList()">
                  <feather-icon
                    icon="PlusCircleIcon"
                    size="16"
                  />
                </button>
              </b-col>
            </b-row>
            <b-row v-if="extra_work !== []">
              <b-col md="12">
                <b-row>
                  <b-col md="2" class="text-center">
                    <b>Építés vezető</b>
                  </b-col>
                  <b-col md="2" class="text-center">
                    <b>Előkészítő</b>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-row v-for="(work, index) in extra_work" :key="index" class="align-items-center">
                  <b-col md="12" v-if="index == 0">
                    <hr/>
                  </b-col>
                  <b-col md="2" class="text-center" style="cursor:pointer" v-if="foreman == getUserID() || checkRole()" @click="changeExtraJobStatusForeman(index)">
                    <feather-icon
                      v-if="work.foreman"
                      icon="CheckCircleIcon"
                      size="26"
                      class="text-success"
                    />
                    <feather-icon
                      v-else
                      icon="MinusCircleIcon"
                      size="26"
                      class="text-danger"
                    />
                  </b-col>
                  <b-col v-else md="2" class="text-center">
                    <feather-icon
                      v-if="work.foreman"
                      icon="CheckCircleIcon"
                      size="26"
                      class="text-success"
                    />
                    <feather-icon
                      v-else
                      icon="MinusCircleIcon"
                      size="26"
                      class="text-danger"
                    />
                  </b-col>

                  <b-col md="2" class="text-center" style="cursor:pointer" v-if="preparatory == getUserID() || checkRole()" @click="changeExtraJobStatusPreparatory(index)">
                    <feather-icon
                      v-if="work.preparatory"
                      icon="CheckCircleIcon"
                      size="26"
                      class="text-success"
                    />
                    <feather-icon
                      v-else
                      icon="MinusCircleIcon"
                      size="26"
                      class="text-danger"
                    />
                  </b-col>
                  <b-col v-else md="2" class="text-center">
                    <feather-icon
                      v-if="work.preparatory"
                      icon="CheckCircleIcon"
                      size="26"
                      class="text-success"
                    />
                    <feather-icon
                      v-else
                      icon="MinusCircleIcon"
                      size="26"
                      class="text-danger"
                    />
                  </b-col>
                  <b-col md="7">
                    <b>{{ work.extra_work }}</b>
                  </b-col>
                  <b-col md="1">
                    <button class="btn btn-primary w-100" @click="removeFromExtraList(index)">
                      <feather-icon
                        icon="MinusCircleIcon"
                        size="16"
                      />
                    </button>
                  </b-col>
                  <b-col md="12">
                    <hr/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Banki garancia">
        <b-row class="mb-2">
          <b class="col-lg-8 mt-2 mb-1">Banki garancia</b>
          <b-col
            cols="4"
            class="mb-2 justify-content-end d-flex"
          >
            <button class="btn btn-primary" @click="formSubmitted">Frissítés</button>
          </b-col>
          <b-col cols="12">
            <p><input type="checkbox" v-model="bank_enabled"> Banki garancia ellenőrzése</p>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Garancia szám"
              label-for="bank_number"
            >
              <b-form-input
                id="bank_number"
                v-model="bank_number"
                type="text"
                placeholder="Garancia szám"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Kiadás dátuma"
              label-for="bank_start_date"
            >
              <b-form-input
                id="bank_start_date"
                v-model="bank_start_date"
                type="date" max="9999-12-31"
                placeholder="Kiadás dátuma"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Lejárat dátuma"
              label-for="bank_end_date"
            >
              <b-form-input
                id="bank_end_date"
                v-model="bank_end_date"
                type="date" max="9999-12-31"
                placeholder="Lejárat dátuma"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Kifizetés dátuma"
              label-for="bank_pay_date"
            >
              <b-form-input
                id="bank_pay_date"
                v-model="bank_pay_date"
                type="date" max="9999-12-31"
                placeholder="Kifizetés dátuma"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Összeg"
              label-for="bank_price"
            >
              <b-form-input
                id="bank_price"
                v-model="bank_price"
                type="number"
                placeholder="Összeg"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <br class="d-none d-md-block d-lg-block"/>
            <button class="btn btn-primary w-100" @click="addToBankList">
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
              />
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" v-if="index != 0">
            <b-row>
              <b-col md="1">
                <b>Azonosító</b>
              </b-col>
              <b-col md="2">
                <b>Kiadás dátuma</b>
              </b-col>
              <b-col md="2">
                <b>Lejárat dátuma</b>
              </b-col>
              <b-col md="2">
                <b>Kifizetés dátuma</b>
              </b-col>
              <b-col md="3">
                <b>Összeg</b>
              </b-col>
            </b-row>
            <b-row v-for="(bank, index) in banks" :key="index">
              <b-col md="12" v-if="index == 0">
                <hr/>
              </b-col>
              <b-col md="1">
                {{ bank.number }}
              </b-col>
              <b-col md="2">
                {{ bank.start_date }}
              </b-col>
              <b-col md="2">
                {{ bank.end_date }}
              </b-col>
              <b-col md="2">
                {{ bank.pay_date }}
              </b-col>
              <b-col md="4">
                {{ parseInt(bank.price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </b-col>
              <b-col md="1">
                <button class="btn btn-primary w-100" @click="removeFromBankList(index)">
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                  />
                </button>
              </b-col>
              <b-col md="12">
                <hr/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Módosítások">
        <b-row class="mb-2">
          <b class="col-lg-8 mt-2 mb-1">Szerződés módosítások</b>
          <b-col
            cols="4"
            class="mb-2 justify-content-end d-flex"
          >
            <button class="btn btn-primary" @click="formSubmitted">Frissítés</button>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Szerzódés módosítás megnevezése"
              label-for="contract_name"
            >
              <b-form-input
                id="contract_name"
                v-model="contract_name"
                type="text"
                placeholder="Szerzódés módosítás megnevezése"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Szerződés módosítás kelte"
              label-for="contract_date"
            >
              <b-form-input
                id="contract_date"
                v-model="contract_date"
                type="date" max="9999-12-31"
                placeholder="Szerződés módosítás kelte"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Új dátum (ha van)"
              label-for="contract_new_date"
            >
              <b-form-input
                id="contract_new_date"
                v-model="contract_new_date"
                type="date" max="9999-12-31"
                placeholder="Új dátum (ha van)"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Új vállalási összeg (ha van)"
              label-for="contract_new_price"
            >
              <b-form-input
                id="contract_new_price"
                v-model="contract_new_price"
                type="number"
                placeholder="Új vállalási összeg (ha van)"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <br class="d-none d-md-block d-lg-block"/>
            <button class="btn btn-primary w-100" @click="addToInvoiceList">
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
              />
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" v-if="index != 0">
            <b-row>
              <b-col md="3">
                <b>Szerződés neve</b>
              </b-col>
              <b-col md="2">
                <b>Szerződés kelte</b>
              </b-col>
              <b-col md="2">
                <b>Új dátum</b>
              </b-col>
              <b-col md="3">
                <b>Új vállalási összeg</b>
              </b-col>
            </b-row>
            <b-row v-for="(invoice_change, index) in invoice_changes" :key="index">
              <b-col md="12" v-if="index == 0">
                <hr/>
              </b-col>
              <b-col md="3">
                {{ invoice_change.contract_name }}
              </b-col>
              <b-col md="2">
                {{ invoice_change.contract_date }}
              </b-col>
              <b-col md="2">
                {{ invoice_change.contract_new_date }}
              </b-col>
              <b-col md="3">
                <span v-if="invoice_change.contract_new_price !== null">{{ parseInt(invoice_change.contract_new_price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</span>
              </b-col>
              <b-col md="1">
                <button class="btn btn-primary w-100" @click="removeFromInvoiceList(index)">
                  <feather-icon
                    icon="Trash2Icon"
                    size="16"
                  />
                </button>
              </b-col>
              <b-col md="12">
                <hr/>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content title="Anyagok">
        <b-row class="mb-2">
          <b class="col-lg-8 mt-2 mb-1">Anyag készlet</b>
          <b-col
            cols="4"
            class="mb-2 justify-content-end d-flex"
          >
            <button class="btn btn-primary" @click="formSubmitted">Frissítés</button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" class="mb-1"><b>Termékek hozzáadása</b></b-col>
          <b-col md="4">
            <b-form-group
              label="Termék neve"
              label-for="product"
            >
              <vue-autosuggest
                v-model="product"
                @selected="setProduct"
                :suggestions="filteredOptions2"
                :limit="10"
                :input-props="{id:'autosuggest__input2',class:'form-control', placeholder:'Termék neve'}"
                @input="onInputChange2"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.product }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Mennyiség"
              label-for="quantity"
            >
              <b-form-input
                id="quantity"
                type="number"
                v-model="quantity"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Mennyiségi egység"
              label-for="unit"
            >
              <vue-autosuggest
                v-model="unit"
                @selected="setUnit"
                :suggestions="filteredOptions"
                :limit="10"
                :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Mennyiségi egység'}"
                @input="onInputChange"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.supplier }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <br>
            <button class="btn btn-primary w-100" @click="addItem()">
              <feather-icon
                icon="PlusCircleIcon"
                size="16"
              />
            </button>
          </b-col>
        </b-row>
        <div>
          <table class="table">
            <tr>
              <th>Termék</th>
              <th>Mennyiség</th>
              <th>Mennyiségi egység</th>
              <th>Lerendelt</th>
              <th></th>
            </tr>
            <tr v-for="(product, index) in material_set" :key="index">
              <td>
                {{ product.product }}
              </td>
              <td>
                {{ product.quantity }}
              </td>
              <td>
                {{ product.unit }}
              </td>
              <td>
                <span v-if="extra_material[index]">{{ extra_material[index].used }}</span>
                <span v-else>0</span>
              </td>
              <td class="float-right">
                <button class="btn btn-danger" @click="removeItem(index)"><feather-icon icon="XCircleIcon" size="16"/></button>
              </td>
            </tr>
          </table>
        </div>
      </tab-content>
    </form-wizard>
    <b-modal id="edit_milestone" title="Mérföldkő szerkesztése" hide-footer>
      <b-col md="12">
        <b-form-group
          label="Mérföldkő neve"
          label-for="milestone_name"
        >
          <b-form-input
            id="milestone_name"
            v-model="edit_milestone_name"
            type="text"
            placeholder="Mérföldkő neve"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Teljesítés dátuma"
          label-for="milestone_date"
        >
          <b-form-input
            id="milestone_date"
            v-model="edit_milestone_date"
            type="date" max="9999-12-31"
            placeholder="Teljesítés dátuma"
          />
        </b-form-group>
      </b-col>
      <b-col md="12" class="text-right">
        <button class="btn btn-primary" @click="updateMilestone()">Frissítés</button>
      </b-col>
    </b-modal>
    <b-modal id="edit_subcontractor" title="Alvállalkozó szerkesztése" hide-footer>
      <b-col md="12">
        <b-form-group
          label="Alvállalkozó"
          label-for="edit_subcontractor_name"
        >
          <b-form-select
            id="edit_subcontractor_name"
            v-model="edit_subcontractor_name"
            :options="subcontractorOptions"
            :value-field="'id'"
            :text-field="'company_name'"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Elvégzendő munka"
          label-for="edit_subcontractor_job"
        >
          <b-form-select
            id="edit_subcontractor_job"
            v-model="edit_subcontractor_job"
            :options="jobOptions"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Kezdés dátuma"
          label-for="edit_subcontractor_start"
        >
          <b-form-input
            id="edit_subcontractor_start"
            v-model="edit_subcontractor_start"
            type="date" max="9999-12-31"
            placeholder="Kezdés dátuma"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Befejezés dátuma"
          label-for="edit_subcontractor_end"
        >
          <b-form-input
            id="edit_subcontractor_end"
            v-model="edit_subcontractor_end"
            type="date" max="9999-12-31"
            placeholder="Befejezés dátuma"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Megjegyzés"
          label-for="edit_subcontractor_text"
        >
          <b-form-textarea
            id="edit_subcontractor_text"
            v-model="edit_subcontractor_text"
            placeholder="Megjegyzés"
          />
        </b-form-group>
      </b-col>
      <b-col md="12" class="text-right">
        <button class="btn btn-primary" @click="updateSubcontractor()">Frissítés</button>
      </b-col>
    </b-modal>
  </div>
</template>

<script>
import '@core/scss/vue/libs/vue-autosuggest.scss'
import { VueAutosuggest } from 'vue-autosuggest'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getAuthUsers } from '@/middleware/authLoader'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BCard,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  watch: {
    work_number(val) {
      if (this.work_number.substring(0, 2) === 'MP') {
        this.monolit = 'MP'
      } else {
        this.monolit = 'ME'
      }
    },
  },
  data() {
    return {
      getAuthUsers,
      extra_material: [],
      filteredOptions: [],
      filteredOptions2: [],
      datasuggest2: [],
      datasuggest: [],
      product: '',
      unit: '',
      employeeOptions: [],
      edit_milestone_date: null,
      edit_milestone_name: null,
      edit_milestone_index: null,
      edit_subcontractor_name: null,
      edit_subcontractor_job: null,
      edit_subcontractor_start: null,
      subcontractor_start: null,
      edit_subcontractor_end: null,
      edit_subcontractor_text: null,
      edit_subcontractor_index: null,
      bank_number: null,
      work_number: null,
      startIndex: 0,
      name: null,
      start_date: null,
      end_date: null,
      customer: null,
      milestones: null,
      bank_start_date: null,
      bank_end_date: null,
      bank_price: null,
      banks: [],
      building_permit: null,
      description: null,
      price: null,
      fad: null,
      foreman: null,
      invoice_changes: [],
      milestone_name: null,
      milestone_date: null,
      preparatory: null,
      subcontractor_job: null,
      subcontractor_text: null,
      subcontractorOptions: [],
      limit: 10,
      selectedSubcontractor: null,
      selectedJob: null,
      jobOptions: ['betonacél szerelés', 'beton szállítás', 'betonacél szállítás', 'földmunka', 'komplett szerkezetépítés', 'kőműves munkák', 'szerkezetépítés', 'vízszigetelés', 'zsalu bontás', 'takarítás', 'gépészet'],
      insurance_end_date: null,
      insurance: null,
      bank_enabled: false,
      bank_pay_date: null,
      extra_work: [],
      extra_description: null,
      subcontractor: [],
      subcontractor_end: null,
      contract_new_date: null,
      contract_new_price: null,
      contract_date: null,
      contract_name: null,
      payment: null,
      monolit: null,
      monolitOptions: [
        {
          text: 'Monolit Épszer Kft.',
          value: 'ME',
        },
        {
          text: 'Monolit Profi Kft.',
          value: 'MP',
        },
      ],
      subcontractors: [],
      work_numberOptions: [],
      fadOptions: [
        {
          text: '+ 27%',
          value: '+ 27%',
        },
        {
          text: '-% FAD',
          value: '-% FAD',
        },
      ],
      material_set: [],
      quantity: null,
    }
  },
  mounted() {
    (async () => {
      this.getAuthUsers('all').then(res => {
        res.forEach(user => {
          this.employeeOptions.push({
            value: user.id,
            text: user.name,
          })
        })
      })
    })()

    this.loadProduct()
    this.loadSupplier()

    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.nextTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()
    this.$refs.wizard.prevTab()

    axios({
      method: 'get',
      url: 'project/task/list_work_number/list',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.work_numberOptions = res.data
    })

    axios({
      method: 'get',
      url: `project/list/${this.$route.params.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.work_number = res.data.work_number
      this.name = res.data.name
      this.start_date = res.data.start_date
      this.end_date = res.data.end_date
      this.customer = res.data.customer
      this.building_permit = res.data.building_permit
      this.description = res.data.description
      this.price = res.data.price
      this.fad = res.data.fad
      this.foreman = res.data.foreman
      this.preparatory = res.data.preparatory
      this.milestones = res.data.milestones
      this.banks = res.data.bank_data
      this.subcontractors = res.data.subcontractor
      this.insurance_end_date = res.data.insurance_end_date
      this.insurance = res.data.insurance
      this.extra_work = res.data.extra
      this.payment = res.data.payment
      this.bank_enabled = res.data.bank_enabled
      this.invoice_changes = res.data.invoice_changes
      this.material_set = res.data.material_set
      this.extra_material = res.data.extra_material

      if (this.work_number.substring(0, 2) === 'MP') {
        this.monolit = 'MP'
      } else {
        this.monolit = 'ME'
      }

      this.price = this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    })

    axios({
      method: 'get',
      url: 'subcontractor/list',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.subcontractorOptions = res.data
    })
  },
  methods: {
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredDevelopers = this.datasuggest.filter(item => item.supplier.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredOptions = [{
        data: filteredData,
      }]
    },
    setUnit(text) {
      this.unit = text.item.name
    },
    setProduct(text) {
      this.product = text.item.name
    },
    loadSupplier() {
      axios({
        method: 'get',
        url: 'order/autocomplete/supplier',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        res.data.forEach(supplier => {
          this.datasuggest.push({
            supplier: supplier.supplier,
            name: supplier.supplier,
          })
        })
      })
    },
    loadProduct() {
      axios({
        method: 'get',
        url: 'order/autocomplete/product',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        res.data.forEach(product => {
          this.datasuggest2.push({
            product: product.product,
            name: product.product,
          })
        })
      })
    },
    removeItem(index) {
      this.material_set.splice(index, 1)
    },
    onInputChange2(text) {
      if (text === '' || text === undefined) {
        return
      }
      const filteredDevelopers = this.datasuggest2.filter(item => item.product.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
      const filteredData = filteredDevelopers
      this.filteredOptions2 = [{
        data: filteredData,
      }]
    },
    addItem() {
      if (this.product === '' || this.quantity === '' || this.unit === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.material_set.push({
          product: this.product,
          quantity: this.quantity,
          unit: this.unit,
        })
        this.product = ''
        this.quantity = ''
        this.unit = ''
      }
    },
    numberWithCommas() {
      this.price = this.price.replace(/\s/g, '')
      this.price = this.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getUserID() {
      return JSON.parse(localStorage.getItem('user')).id
    },
    checkRole() {
      return JSON.parse(localStorage.getItem('user')).role.includes('extra_work')
    },
    changeExtraJobStatusPreparatory(index) {
      this.extra_work[index].preparatory = !this.extra_work[index].preparatory
    },
    addToExtraList() {
      if (this.extra_description === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.extra_work.push({
          extra_work: this.extra_description,
          foreman: false,
          preparatory: false,
        })
        this.extra_description = null
      }
    },
    changeExtraJobStatusForeman(index) {
      this.extra_work[index].foreman = !this.extra_work[index].foreman
    },
    removeFromExtraList(index) {
      this.extra_work.splice(index, 1)
    },
    addToSubcontractorList() {
      if (this.subcontractor_start === null || this.selectedSubcontractor === null || this.subcontractor_end === null || this.selectedJob === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.subcontractors.push({
          id: this.selectedSubcontractor.id,
          name: this.subcontractorOptions.find(subcontractor => subcontractor.id === this.selectedSubcontractor).company_name,
          date: this.subcontractor_start,
          date_end: this.subcontractor_end,
          job: this.selectedJob,
          text: this.subcontractor_text,
        })
        this.selectedJob = null
        this.subcontractor_start = null
        this.selectedSubcontractor = null
        this.subcontractor_end = null
        this.subcontractor_text = null
        this.sortSubcontractorsByDate()
      }
    },
    sortSubcontractorsByDate() {
      this.subcontractors = this.subcontractors.sort((a, b) => new Date(a.date) - new Date(b.date))
    },
    removeFromSubcontractorsList(index) {
      this.subcontractors.splice(index, 1)
      this.sortSubcontractorsByDate()
    },
    updateSubcontractor() {
      this.subcontractors[this.edit_subcontractor_index].id = this.edit_subcontractor_name
      this.subcontractors[this.edit_subcontractor_index].name = this.subcontractorOptions.find(subcontractor => subcontractor.id === this.edit_subcontractor_name).company_name
      this.subcontractors[this.edit_subcontractor_index].job = this.edit_subcontractor_job
      this.subcontractors[this.edit_subcontractor_index].date = this.edit_subcontractor_start
      this.subcontractors[this.edit_subcontractor_index].date_end = this.edit_subcontractor_end
      this.subcontractors[this.edit_subcontractor_index].text = this.edit_subcontractor_text
      this.$bvModal.hide('edit_subcontractor')
    },
    updateMilestone() {
      this.milestones[this.edit_milestone_index].name = this.edit_milestone_name
      this.milestones[this.edit_milestone_index].date = this.edit_milestone_date
      this.$bvModal.hide('edit_milestone')
      this.sortMilestonesByDate()
    },
    sortMilestonesByDate() {
      this.milestones = this.milestones.sort((a, b) => new Date(a.date) - new Date(b.date))
    },
    removeFromMilestoneList(index) {
      this.milestones.splice(index, 1)
      this.sortMilestonesByDate()
    },
    removeFromBankList(index) {
      this.banks.splice(index, 1)
    },
    removeFromInvoiceList(index) {
      this.invoice_changes.splice(index, 1)
    },
    changeMilestoneStatus(status, index) {
      this.milestones[index].done = status
      if (status) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mérföldkő teljesítve',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mérföldkő visszaállítva',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      }
    },
    editFromMilestoneList(index) {
      this.edit_milestone_name = this.milestones[index].name
      this.edit_milestone_date = this.milestones[index].date
      this.edit_milestone_index = index
      this.$bvModal.show('edit_milestone')
    },
    editFromSubcontrator(index) {
      this.edit_subcontractor_end = this.subcontractors[index].date_end
      this.edit_subcontractor_start = this.subcontractors[index].date
      this.edit_subcontractor_job = this.subcontractors[index].job
      this.edit_subcontractor_name = this.subcontractorOptions.find(subcontractor => subcontractor.company_name === this.subcontractors[index].name).id
      this.edit_subcontractor_text = this.subcontractors[index].text
      this.edit_subcontractor_index = index
      this.$bvModal.show('edit_subcontractor')
    },
    addToMilestoneList() {
      if (this.milestone_name === null || this.milestone_date === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.milestones.push({
          name: this.milestone_name,
          date: this.milestone_date,
          done: false,
        })
        this.milestone_name = null
        this.milestone_date = null
        this.sortMilestonesByDate()
      }
    },
    addToBankList() {
      if (this.bank_start_date === null || this.bank_end_date === null || this.bank_price === null || this.bank_number === null || this.bank_pay_date === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.banks.push({
          start_date: this.bank_start_date,
          end_date: this.bank_end_date,
          price: this.bank_price,
          number: this.bank_number,
          pay_date: this.bank_pay_date,
        })
        this.bank_start_date = null
        this.bank_end_date = null
        this.bank_price = null
        this.bank_number = null
        this.bank_pay_date = null
      }
    },
    addToInvoiceList() {
      if (this.contract_name === null || this.contract_date === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'A szerződés módosítás nevét és a szerződés keltét kötelező megadni!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else if (this.contract_new_date === null && this.contract_new_price === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Legalább dátum változást vagy vállalási összeg változást rögzíteni kell!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      } else {
        this.invoice_changes.push({
          contract_name: this.contract_name,
          contract_date: this.contract_date,
          contract_new_price: this.contract_new_price,
          contract_new_date: this.contract_new_date,
        })
        this.contract_name = null
        this.contract_date = null
        this.contract_new_price = null
        this.contract_new_date = null
      }
    },
    getDataByWorkNumber(val) {
      axios({
        method: 'post',
        url: 'project/task/list_work_number/list/data',
        data: {
          work_number: val,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.customer = res.data.company
        this.name = res.data.work_id
      })
    },
    formSubmitted() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kérés elküldve feldolgozásra',
          icon: 'LoaderIcon',
          variant: 'warning',
        },
      })
      axios({
        method: 'put',
        url: 'project/update',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          id: this.$route.params.id,
          work_number: this.work_number,
          name: this.name,
          start_date: this.start_date,
          end_date: this.end_date,
          customer: this.customer,
          building_permit: this.building_permit,
          description: this.description,
          price: this.price.replace(/\s/g, ''),
          fad: this.fad,
          foreman: this.foreman,
          preparatory: this.preparatory,
          milestones: this.milestones,
          subcontractor: this.subcontractors,
          insurance_end_date: this.insurance_end_date,
          insurance: this.insurance,
          extra: this.extra_work,
          bank_data: this.banks,
          bank_enabled: this.bank_enabled,
          invoice_changes: this.invoice_changes,
          monolit: this.monolit,
          material_set: this.material_set,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen létrehozva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.go(-1)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .flatpickr-input{
    display: none !important;
  }
</style>

<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .autosuggest__results-container{
    background: var(--primary);
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .autosuggest__results-item{
    list-style: square;
    margin-top: 3px;
  }
</style>
